export const Empty = () => {
  return <svg width="394" height="293" viewBox="0 0 394 293" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="Empty" data-sentry-source-file="empty.tsx">
         <g opacity="0.74" data-sentry-element="g" data-sentry-source-file="empty.tsx">
            <g clipPath="url(#clip0_8182_21406)" data-sentry-element="g" data-sentry-source-file="empty.tsx">
               <rect width="288.161" height="231.765" rx="10.8157" fill="url(#paint0_linear_8182_21406)" data-sentry-element="rect" data-sentry-source-file="empty.tsx" />
               <rect width="288.933" height="143.694" fill="url(#paint1_linear_8182_21406)" data-sentry-element="rect" data-sentry-source-file="empty.tsx" />
               <rect x="17.3824" y="160.304" width="121.29" height="32.4471" rx="5.79412" fill="#262626" stroke="#343434" strokeWidth="0.772549" data-sentry-element="rect" data-sentry-source-file="empty.tsx" />
            </g>
            <rect x="0.386275" y="0.386275" width="287.388" height="230.992" rx="10.4294" stroke="url(#paint2_linear_8182_21406)" strokeWidth="0.772549" data-sentry-element="rect" data-sentry-source-file="empty.tsx" />
         </g>
         <g filter="url(#filter0_b_8182_21406)" data-sentry-element="g" data-sentry-source-file="empty.tsx">
            <g clipPath="url(#clip1_8182_21406)" data-sentry-element="g" data-sentry-source-file="empty.tsx">
               <rect x="48.6704" y="27.0391" width="288.161" height="231.765" rx="10.8157" fill="url(#paint3_linear_8182_21406)" fill-opacity="0.7" data-sentry-element="rect" data-sentry-source-file="empty.tsx" />
               <rect x="48.6704" y="27.0391" width="288.933" height="143.694" fill="url(#paint4_linear_8182_21406)" fill-opacity="0.45" data-sentry-element="rect" data-sentry-source-file="empty.tsx" />
               <rect x="66.0528" y="187.343" width="121.29" height="32.4471" rx="5.79412" fill="#262626" stroke="#343434" strokeWidth="0.772549" data-sentry-element="rect" data-sentry-source-file="empty.tsx" />
            </g>
            <rect x="49.0567" y="27.4253" width="287.388" height="230.992" rx="10.4294" stroke="url(#paint5_linear_8182_21406)" strokeWidth="0.772549" data-sentry-element="rect" data-sentry-source-file="empty.tsx" />
         </g>
         <g filter="url(#filter1_b_8182_21406)" data-sentry-element="g" data-sentry-source-file="empty.tsx">
            <g clipPath="url(#clip2_8182_21406)" data-sentry-element="g" data-sentry-source-file="empty.tsx">
               <rect x="105.839" y="61.0312" width="288.161" height="231.765" rx="10.8157" fill="url(#paint6_linear_8182_21406)" fill-opacity="0.32" data-sentry-element="rect" data-sentry-source-file="empty.tsx" />
               <rect x="105.839" y="61.0312" width="288.933" height="143.694" fill="url(#paint7_linear_8182_21406)" fill-opacity="0.45" data-sentry-element="rect" data-sentry-source-file="empty.tsx" />
               <rect x="122.835" y="220.949" width="122.063" height="33.2196" rx="6.18039" fill="#262626" fill-opacity="0.25" data-sentry-element="rect" data-sentry-source-file="empty.tsx" />
               <rect x="123.222" y="221.335" width="121.29" height="32.4471" rx="5.79412" stroke="#343434" stroke-opacity="0.09" strokeWidth="0.772549" data-sentry-element="rect" data-sentry-source-file="empty.tsx" />
            </g>
            <rect x="106.226" y="61.4175" width="287.388" height="230.992" rx="10.4294" stroke="url(#paint8_linear_8182_21406)" strokeWidth="0.772549" data-sentry-element="rect" data-sentry-source-file="empty.tsx" />
         </g>
         <defs data-sentry-element="defs" data-sentry-source-file="empty.tsx">
            <filter id="filter0_b_8182_21406" x="39.2453" y="17.614" width="307.011" height="250.615" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB" data-sentry-element="filter" data-sentry-source-file="empty.tsx">
               <feFlood flood-opacity="0" result="BackgroundImageFix" data-sentry-element="feFlood" data-sentry-source-file="empty.tsx" />
               <feGaussianBlur in="BackgroundImageFix" stdDeviation="4.71255" data-sentry-element="feGaussianBlur" data-sentry-source-file="empty.tsx" />
               <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_8182_21406" data-sentry-element="feComposite" data-sentry-source-file="empty.tsx" />
               <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_8182_21406" result="shape" data-sentry-element="feBlend" data-sentry-source-file="empty.tsx" />
            </filter>
            <filter id="filter1_b_8182_21406" x="96.4143" y="51.6062" width="307.011" height="250.615" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB" data-sentry-element="filter" data-sentry-source-file="empty.tsx">
               <feFlood flood-opacity="0" result="BackgroundImageFix" data-sentry-element="feFlood" data-sentry-source-file="empty.tsx" />
               <feGaussianBlur in="BackgroundImageFix" stdDeviation="4.71255" data-sentry-element="feGaussianBlur" data-sentry-source-file="empty.tsx" />
               <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_8182_21406" data-sentry-element="feComposite" data-sentry-source-file="empty.tsx" />
               <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_8182_21406" result="shape" data-sentry-element="feBlend" data-sentry-source-file="empty.tsx" />
            </filter>
            <linearGradient id="paint0_linear_8182_21406" x1="8.11176" y1="4.6353" x2="278.118" y2="231.765" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="empty.tsx">
               <stop stopColor="#2A2929" data-sentry-element="stop" data-sentry-source-file="empty.tsx" />
               <stop offset="1" stopColor="#0E0E0E" data-sentry-element="stop" data-sentry-source-file="empty.tsx" />
            </linearGradient>
            <linearGradient id="paint1_linear_8182_21406" x1="144.467" y1="0" x2="144.467" y2="143.694" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="empty.tsx">
               <stop stopColor="#222222" data-sentry-element="stop" data-sentry-source-file="empty.tsx" />
               <stop offset="1" stopColor="#282828" data-sentry-element="stop" data-sentry-source-file="empty.tsx" />
            </linearGradient>
            <linearGradient id="paint2_linear_8182_21406" x1="288.161" y1="229.061" x2="9.2706" y2="6.56666" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="empty.tsx">
               <stop stopColor="#111010" data-sentry-element="stop" data-sentry-source-file="empty.tsx" />
               <stop offset="1" stopColor="#414141" data-sentry-element="stop" data-sentry-source-file="empty.tsx" />
            </linearGradient>
            <linearGradient id="paint3_linear_8182_21406" x1="56.7822" y1="31.6744" x2="326.788" y2="258.804" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="empty.tsx">
               <stop stopColor="#2A2929" data-sentry-element="stop" data-sentry-source-file="empty.tsx" />
               <stop offset="1" stopColor="#0E0E0E" data-sentry-element="stop" data-sentry-source-file="empty.tsx" />
            </linearGradient>
            <linearGradient id="paint4_linear_8182_21406" x1="193.137" y1="27.0391" x2="193.137" y2="170.733" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="empty.tsx">
               <stop stopColor="#222222" data-sentry-element="stop" data-sentry-source-file="empty.tsx" />
               <stop offset="1" stopColor="#282828" data-sentry-element="stop" data-sentry-source-file="empty.tsx" />
            </linearGradient>
            <linearGradient id="paint5_linear_8182_21406" x1="336.831" y1="256.1" x2="57.941" y2="33.6057" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="empty.tsx">
               <stop stopColor="#111010" data-sentry-element="stop" data-sentry-source-file="empty.tsx" />
               <stop offset="1" stopColor="#414141" data-sentry-element="stop" data-sentry-source-file="empty.tsx" />
            </linearGradient>
            <linearGradient id="paint6_linear_8182_21406" x1="113.951" y1="65.6666" x2="383.957" y2="292.796" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="empty.tsx">
               <stop stopColor="#2A2929" data-sentry-element="stop" data-sentry-source-file="empty.tsx" />
               <stop offset="1" stopColor="#0E0E0E" data-sentry-element="stop" data-sentry-source-file="empty.tsx" />
            </linearGradient>
            <linearGradient id="paint7_linear_8182_21406" x1="250.306" y1="61.0312" x2="250.306" y2="204.725" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="empty.tsx">
               <stop stopColor="#222222" data-sentry-element="stop" data-sentry-source-file="empty.tsx" />
               <stop offset="1" stopColor="#282828" data-sentry-element="stop" data-sentry-source-file="empty.tsx" />
            </linearGradient>
            <linearGradient id="paint8_linear_8182_21406" x1="394" y1="290.092" x2="115.11" y2="67.5979" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="empty.tsx">
               <stop stopColor="#111010" data-sentry-element="stop" data-sentry-source-file="empty.tsx" />
               <stop offset="1" stopColor="#414141" data-sentry-element="stop" data-sentry-source-file="empty.tsx" />
            </linearGradient>
            <clipPath id="clip0_8182_21406" data-sentry-element="clipPath" data-sentry-source-file="empty.tsx">
               <rect width="288.161" height="231.765" rx="10.8157" fill="white" data-sentry-element="rect" data-sentry-source-file="empty.tsx" />
            </clipPath>
            <clipPath id="clip1_8182_21406" data-sentry-element="clipPath" data-sentry-source-file="empty.tsx">
               <rect x="48.6704" y="27.0391" width="288.161" height="231.765" rx="10.8157" fill="white" data-sentry-element="rect" data-sentry-source-file="empty.tsx" />
            </clipPath>
            <clipPath id="clip2_8182_21406" data-sentry-element="clipPath" data-sentry-source-file="empty.tsx">
               <rect x="105.839" y="61.0312" width="288.161" height="231.765" rx="10.8157" fill="white" data-sentry-element="rect" data-sentry-source-file="empty.tsx" />
            </clipPath>
         </defs>
      </svg>;
};