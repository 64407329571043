export const Fitness = () => {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="Fitness" data-sentry-source-file="fitness.tsx">
         <g clipPath="url(#clip0_8059_9048)" data-sentry-element="g" data-sentry-source-file="fitness.tsx">
            <path d="M4.33325 4.33325L11.6666 11.6666M13.9999 13.9999L13.3333 13.3333M1.99992 1.99992L2.66659 2.66659M11.9999 14.6666L14.6666 11.9999M1.33325 3.99992L3.99992 1.33325M1.99992 6.66658L6.66658 1.99992M9.33325 13.9999L13.9999 9.33325" stroke="#FAFAFA" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="fitness.tsx" />
         </g>
         <defs data-sentry-element="defs" data-sentry-source-file="fitness.tsx">
            <clipPath id="clip0_8059_9048" data-sentry-element="clipPath" data-sentry-source-file="fitness.tsx">
               <rect width="16" height="16" fill="white" data-sentry-element="rect" data-sentry-source-file="fitness.tsx" />
            </clipPath>
         </defs>
      </svg>;
};