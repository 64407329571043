import { GROUP_PAGE_MENU, GroupMenuProps, LANDING_PAGE_MENU, MenuProps } from "./menus";
import { SIGN_UP_FORM, SIGN_IN_FORM, AuthFormProps } from "./forms";
import { CREATE_GROUP_PLACEHOLDER, CreateGroupPlaceholderProps } from "./placeholder";
import { GROUP_LIST, GroupListProps } from "./slider";
const customColors = {
  draft: "bg-[#fca5a5]",
  processing: "bg-[#3b82f6]",
  ready: "bg-[#bef264]",
  special: "bg-[#8b5cf6]",
  success: "bg-success",
  default: "bg-default"
} as const;
const LegacyPlanColor = "#ff4d4d";
export const APP_ROOT_DOMAIN = process.env.NODE_ENV === "development" ? process.env.NGROK_URL : process.env.NEXT_PUBLIC_APP_URL;
export const APP_NON_NGROK_DOMAIN = process.env.NODE_ENV === "development" ? "http://localhost:3000" : process.env.NEXT_PUBLIC_APP_URL;
export const ASSETS_DOMAIN = process.env.NEXT_PUBLIC_ASSETS_DOMAIN || "https://viral.devassets.app";

// Sentry DSN - in dev mode set as undefined
export const SENTRY_DSN = process.env.NODE_ENV === "development" ? undefined : process.env.NEXT_PUBLIC_SENTRY_DSN;
type ColorTheme = {
  gradient: {
    from: string;
    to: string;
    hover: string;
    overlay: string;
  };
  particle: string;
  darkMode: {
    from: string;
    hover: string;
  };
  text?: string;
};
export const colorThemes: Record<string, ColorTheme> = {
  amber: {
    gradient: {
      from: "from-amber-500/5",
      to: "to-card",
      hover: "from-amber-500/10",
      overlay: "from-amber-500/10 via-transparent to-amber-500/5"
    },
    particle: "#f59e0b",
    darkMode: {
      from: "dark:from-amber-900/10",
      hover: "dark:hover:from-amber-900/20"
    },
    text: "text-amber-500"
  },
  emerald: {
    gradient: {
      from: "from-emerald-500/5",
      to: "to-card",
      hover: "from-emerald-500/10",
      overlay: "from-emerald-500/10 via-transparent to-emerald-500/5"
    },
    particle: "#10b981",
    darkMode: {
      from: "dark:from-emerald-900/10",
      hover: "dark:hover:from-emerald-900/20"
    }
  },
  purple: {
    gradient: {
      from: "from-purple-500/5",
      to: "to-card",
      hover: "from-purple-500/10",
      overlay: "from-purple-500/10 via-transparent to-purple-500/5"
    },
    particle: "#8b5cf6",
    darkMode: {
      from: "dark:from-purple-900/10",
      hover: "dark:hover:from-purple-900/20"
    }
  },
  rose: {
    gradient: {
      from: "from-rose-500/5",
      to: "to-card",
      hover: "from-rose-500/10",
      overlay: "from-rose-500/10 via-transparent to-rose-500/5"
    },
    particle: "#f43f5e",
    darkMode: {
      from: "dark:from-rose-900/10",
      hover: "dark:hover:from-rose-900/20"
    }
  },
  sky: {
    gradient: {
      from: "from-sky-500/5",
      to: "to-card",
      hover: "from-sky-500/10",
      overlay: "from-sky-500/10 via-transparent to-sky-500/5"
    },
    particle: "#0ea5e9",
    darkMode: {
      from: "dark:from-sky-900/10",
      hover: "dark:hover:from-sky-900/20"
    }
  }
};
export const containerVariants = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};
export const itemVariants = {
  hidden: {
    y: 20,
    opacity: 0
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 100
    }
  }
};
export const iconClasses = "text-xl text-default-500 pointer-events-none flex-shrink-0";
export const enableEmbedTasks = process.env.NEXT_PUBLIC_EMBED_TASKS === "true" ? true : false;
type GroupleConstantsProps = {
  landingPageMenu: MenuProps[];
  signUpForm: AuthFormProps[];
  signInForm: AuthFormProps[];
  groupList: GroupListProps[];
  createGroupPlaceholder: CreateGroupPlaceholderProps[];
  groupPageMenu: GroupMenuProps[];
};
export const GROUPLE_CONSTANTS: GroupleConstantsProps = {
  landingPageMenu: LANDING_PAGE_MENU,
  signUpForm: SIGN_UP_FORM,
  signInForm: SIGN_IN_FORM,
  groupList: GROUP_LIST,
  createGroupPlaceholder: CREATE_GROUP_PLACEHOLDER,
  groupPageMenu: GROUP_PAGE_MENU
};